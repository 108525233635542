import PhotoSwipe from "photoswipe";
import PhotoSwipeUI from "photoswipe/dist/photoswipe-ui-default";

/**
 * Convert the data-* attribute from the <img> elements to PhotoSwipe Items
 * @param dataset
 */
const elementToPhotoSwipeItem = (el: HTMLElement): PhotoSwipe.Item => ({
  el,
  src: el.dataset.src,
  w: parseInt(el.dataset.width || "0", 10),
  h: parseInt(el.dataset.height || "0", 10)
});

/**
 * Return the bounds of an element (used as starting point for opening animation)
 * @param el
 */
const getElementBounds = (el: HTMLElement) => {
  const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
  const rect = el.getBoundingClientRect();
  return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
};

/**
 * @param selector
 */
const createPhotoSwipeGallery = (selector: string) => {
  const el = document.querySelector(".pswp");
  if (!(el instanceof HTMLElement)) {
    return;
  }
  const photos: Array<HTMLElement> = Array.from(
    document.querySelectorAll(selector)
  );
  const items: PhotoSwipe.Item[] = photos.map(elementToPhotoSwipeItem);
  const getThumbBoundsFn = (index: number) => getElementBounds(items[index].el);
  const openPhotoSwipe = (index: number) => {
    const options = { index, getThumbBoundsFn };
    const gallery = new PhotoSwipe(el, PhotoSwipeUI, items, options);
    gallery.init();
  };
  photos.forEach(photo => {
    photo.addEventListener("click", () => {
      openPhotoSwipe(photos.indexOf(photo));
    });
  });
};









const radioButtons = Array.from(document.querySelectorAll("#horses input[type=radio]"));
const horses = document.querySelector('#horses');

radioButtons.forEach(button => {
  button.addEventListener("change", el => {
    horses.classList.remove("show-all");
    horses.classList.remove("show-current");
    horses.classList.remove("show-for-sale");
    horses.classList.add(el.target.value);
  })
})


const updateHorseFiltersCounters = () => {
  const all = document.querySelectorAll('.horse').length;
  const forSale = document.querySelectorAll('.horse--for-sale').length;
  const haveLeft = document.querySelectorAll('.horse--has-left').length;

  const totals: { [key: string]: number } = {
    "all": all,
    "current": all - haveLeft,
    "for-sale": forSale,
  };

  Object.keys(totals).forEach((total) => {
    const el = document.querySelector(`.total--${total}`);
    if (el) {
      el.innerHTML = `(${totals[total]})`;
    }
  });
};


const init = () => {
  createPhotoSwipeGallery("#photos img");
  updateHorseFiltersCounters();
};

init();
